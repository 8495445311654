<template>
    <div
        class="chat_contact flx wfl"
        :class="active && 'active'"
        @click="selectChat()">
        <div class="awatar_wrapper">
            <template v-if="chat.is_public">
                <a-avatar
                    :size="38"
                    :style="chat.color ? `backgroundColor:${chat.color}` : 'backgroundColor: #cccccc'">
                    {{ avatarText }}
                </a-avatar>
            </template>
            <template v-else>
                <a-badge :color="statusColor">
                    <a-avatar
                        v-if="chat.avatar"
                        :key="chat.id"
                        :size="38"
                        :style="chat.color ? `backgroundColor:${chat.color}` : 'backgroundColor: #cccccc'"
                        :src="chat.avatar.path ? chat.avatar.path : null" />
                    <a-avatar
                        v-else
                        :size="38"
                        :src="getAvatar"
                        :style="chat.color ? `backgroundColor:${chat.color}` : 'backgroundColor: #cccccc'">
                        {{ avatarText }}
                    </a-avatar>
                </a-badge>
            </template>
        </div>
        <div class="user_item__body pl-2 truncate" style="width: 100%">
            <div class="name flex justify-between items-center">
                <div class="truncate font-semibold flex items-center">
                    <i v-if="chat.is_public" class="fi fi-rr-users text-xs mr-1"></i>
                    <template v-if="chat.recipient && chat.recipient.is_support" >
                        <span 
                            class="text-xs mr-1"
                            v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }" 
                            :content="$t('chat.support')">
                            <i class="fi fi-rr-headset"></i>
                        </span>
                    </template>
                    <span class="truncate text-sm"> {{ chat.name }} </span>
                </div>
                <span class="text-xs pl-1 gray">
                    {{ lastSend }}
                </span>
            </div>

            <div class="flex justify-between align-center">
                <ChatContactLastMessage :chat="chat" />
                <a-badge
                    v-if="chat.new_message_count > 0"
                    :count="chat.new_message_count"
                    :number-style="{ backgroundColor: primaryColor }"
                    class="pl-1" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ChatContactLastMessage from './ChatContactLastMessage.vue'
export default {
    name: "ChatContact",
    components: {
        ChatContactLastMessage
    },
    props: {
        chat: {
            type: Object,
            required: true
        }
    },
    data() {
        return{
            listUsers:[]
        }

    },
    sockets:{
        chat_online_user(data) {
            const userId = data.user
            const foundUserIndex = this.listUsers.findIndex(user => user.user_uid === userId)

            if (foundUserIndex > -1) {
                this.listUsers[foundUserIndex].online = true
            }
        },
        chat_offline_user(data) {

            const userId = data.user
            const foundUser = this.listUsers.find(user => user.user_uid === userId)
            if (foundUser) {
                foundUser.online = false
                foundUser.last_activity = data.last_activity
            }
        },
        chat_status_user(data) {
            const sockerUser = data.members
            const foundUser = this.listUsers.find(user => user.user_uid === sockerUser.user_uid)
            if (foundUser) {

                foundUser.user_uid = sockerUser.user_uid
                foundUser.last_activity = sockerUser.last_activity
                foundUser.online = sockerUser.online
            } else
                this.listUsers.push(sockerUser)
        },
    },
    computed: {
        ...mapState({
            activeChat: state => state.chat.activeChat,
            user: state => state.user.user,
            isMobile: state => state.isMobile,
            primaryColor: state => state.config.primaryColor
        }),
        avatarText() {
            if(this.chat.is_public) {
                return this.chat.name.charAt(0).toUpperCase()
            } else {
                const n = this.chat.name.split(' ')
                return `${n[0].charAt(0).toUpperCase()}${n[1] ? n[1].charAt(0).toUpperCase() : ''}${n[2] ? n[2].charAt(0).toUpperCase() : ''}`
            }
        },
        statusColor() {
            if(this.chat.recipient?.last_activity) {
                if(this.isOnline)
                    return '#52c41a'
                else
                    return '#f5222d'
            } else
                return '#808080'
        },
        firstCheck() {
            if(this.chat?.recipient?.id)
                return this.$store.getters['user/getUserFirstCheck'](this.chat.recipient.id)
            else
                return null
        },
        isOnline() {
            if(this.chat?.recipient?.id)
                return this.$store.getters['user/getUserStatus'](this.chat.recipient.id)
            else
                return null
        },
        lastSend() {
            if(this.$moment(this.chat.last_sent).isBefore(this.$moment(), 'days')) {
                if(this.$moment(this.chat.last_sent).isBefore(this.$moment(), 'week'))
                    return this.$moment(this.chat.last_sent).format('DD.MM.YY')
                else
                    return this.$moment(this.chat.last_sent).format('dd')
            } else
                return this.$moment(this.chat.last_sent).format('HH:mm')
        },
        getAvatar() {
            if (this.chat.chat_author || this.chat.recipient) {
                if (this.chat.chat_author.id !== this.user.id) {
                    this.$socket.client.emit('chat_status_user', {chat_uid: "0", user_uid: this.chat.chat_author.id})
                    return this.chat.chat_author.avatar?.path || null
                } else {
                    if (this.chat.recipient) {
                        this.$socket.client.emit('chat_status_user', {chat_uid: "0", user_uid: this.chat.recipient.id})
                        return this.chat.recipient.avatar?.path || null
                    }
                }
            }
            return ''
        },
        active() {
            if(this.activeChat?.chat_uid === this.chat.chat_uid)
                return true
            else
                return false
        }
    },
    created() {
        if(!this.firstCheck && this.chat.recipient?.last_activity) {
            this.$store.commit('user/SET_ONLINE_USER_EVENT', this.chat.recipient)
        }
    },
    methods: {
        ...mapMutations({
            SET_ACTIVE_CHAT: 'chat/SET_ACTIVE_CHAT',
            SET_CHAT_MESSAGE: 'chat/SET_CHAT_MESSAGE',
            SET_CHAT_MESSAGE_MODAL: 'chat/SET_CHAT_MESSAGE_MODAL'
        }),
        selectChat() {
            if(this.isMobile) {
                this.$router.push({
                    name: 'chat-body',
                    params: {
                        id: this.chat.chat_uid
                    }})
            } else {
                this.SET_ACTIVE_CHAT(this.chat)
                this.SET_CHAT_MESSAGE(this.chat.chat_uid)
                this.SET_CHAT_MESSAGE_MODAL(this.chat.chat_uid)
                if(this.activeChat && !this.activeChat.is_public)
                    this.$socket.client.emit('chat_status_user',
                        {chat_uid: this.activeChat.chat_uid, user_uid: this.activeChat.recipient.id})

                let query = Object.assign({}, this.$route.query)

                if(query?.chat_id !== this.chat.chat_uid) {
                    query.chat_id = this.chat.chat_uid
                    this.$router.push({query})
                }
            }
        }
    }
}
</script>

<style lang="scss">
.chat_contact{
    padding: 10px;
    cursor: pointer;
    align-items: center;
    .message_desc{
        font-size: 13px;
        color: #747679;
    }
    &.active{
        background: var(--primaryHover);
    }
    .user_item__body{
        .name{
            font-size: 15px;
            .team_icon{
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
}


    /*.user_list_wrap {*/
        /*max-height: 300px;*/
        /*padding: 15px;*/
        /*padding-top: 0;*/
        /*overflow-y: scroll;*/
    /*}*/

    .ant-badge-dot {
        top: 5px;
        right: 3px;

        width: 8px !important;
        height: 8px !important;
    }

</style>